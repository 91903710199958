/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Spin,
} from 'antd';
import {
  UploadCard,
} from 'components';
import {
  Card,
  Drawer,
  Input,
} from 'components/gyramais';
import {
  Modal,
} from 'components/sdk';
import {
  badesulPortfolios,
  translateMonth,
} from 'utils';
import { useMutation, useQuery } from '@apollo/client';

import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import ME from './graphql/me.gql';
import SAVE_BUSINESS from './graphql/saveBusiness.gql';

const ByAnnualDrawer = ({
  visible,
  onClose,
  onComplete,
}) => {
  const { businessId, currentContext } = useBusinessLoanContext();
  const { checkActions } = usePartnerActionsContext();
  const onlyByAnnualBilling = checkActions(['show-only-bi-anual-billing']);

  const [alertStatementModal, setAlertStatementModal] = useState(false);
  const [statementsValues, setStatementsValues] = useState([]);
  const [statementsFilesAlreadyAdded, setStatementsFilesAlreadyAdded] = useState([]);
  const [statementsFilesIds, setStatementsFilesIds] = useState([]);
  const [savingBadesc, setSavingBadesc] = useState(false);

  const {
    loading: loadingMe,
  } = useQuery(ME, {
    onCompleted: ({ me }) => {
      const businessStatements = me.currentBusiness.revenueStatementValues ?? [];
      const revenueStatements = me.currentBusiness.revenueStatement ?? [];

      const emptyStatements = [];

      const today = new Date();
      // Basicamente ele pega o mês atual e vai voltando 24 meses
      for (let x = 1; x <= 24; x++) {
        const date = new Date(today.getFullYear(), today.getMonth() - x, 1, 0, 0, 0, 0);
        const mesano = `${date.getMonth() + 1}${date.getFullYear()}`;
        const statement = businessStatements
          .find(({ mesano: statementMesano }) => statementMesano === mesano);
        emptyStatements.push({
          id: x,
          label: `${translateMonth(date.getMonth())} de ${date.getFullYear()}`,
          reference: new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`),
          value: statement?.value || 0,
          mesano,
        });
      }
      setStatementsValues(emptyStatements.reverse());

      if (revenueStatements[0]) {
        setStatementsFilesAlreadyAdded(revenueStatements);
        setStatementsFilesIds(revenueStatements.map(({ id }) => id));
      }
    },
  });

  const [saveBusiness, { loading: loadingSaveBusiness }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      setSavingBadesc(true);
      onComplete();
    },
    onError: () => {
      setSavingBadesc(false);
    },
  });

  const isBadesul = badesulPortfolios.includes(currentContext?.loan?.portfolioName);

  const validateStatements = () => {
    if (isBadesul) {
      const last7Statements = statementsValues.slice(-7);
      const isLast7Completed = last7Statements.every(({ value }) => Boolean(Number(value)));

      if (!isLast7Completed) {
        return false;
      }
    } else {
      const statementsWithValue = statementsValues.filter(({ value }) => Boolean(Number(value)));
      if (statementsWithValue.length < 7) {
        return false;
      }
    }

    const files = statementsFilesIds.length;
    if (!files) {
      // Sei que tem como escrever com menos linhas, só que assim fica mais legível :)
      return false;
    }

    return true;
  };

  const handleRevenueStatement = async () => {
    const isStatementsValid = validateStatements();
    if (isStatementsValid) {
      saveBusiness({
        variables: {
          id: businessId || currentContext.business.id,
          revenueStatementFilesIds: statementsFilesIds,
          revenueStatementValues: statementsValues.map(({ value, mesano, reference }) => ({
            value,
            mesano,
            reference,
          })),
        },
      });
      return true;
    }
    return setAlertStatementModal(true);
  };

  const loading = loadingMe || loadingSaveBusiness || savingBadesc;

  const hasCooperativa = currentContext?.loan?.additionalInfo?.creationData?.dadosCooperativa?.nrCnpj;
  const alertMessage = hasCooperativa ? 'Relação de faturamento dos últimos 24 meses, mês a mês' : 'Relação de faturamento dos últimos 24 meses, mês a mês, assinado por certificado digital pelo contador, sob carimbo e registro no Conselho Regional de Contabilidade (CRC).';

  return (
    <>
      <Drawer
        title="Faturamento dos últimos 24 meses"
        visible={visible}
        primaryButton={{
          children: 'Confirmar',
          onClick: handleRevenueStatement,
          loading,
        }}
        onClose={() => onClose()}
        closable={!onlyByAnnualBilling}
        maxWidth="100%"
        drawerStyle={onlyByAnnualBilling ? {
          width: 'calc(100% - 76px)',
        } : {}}
      >
        <Spin spinning={loading}>
          <Card className="statements-inputs-card">
            {statementsValues.map(({ label, id: index, value }) => (
              <Row align="middle" key={index} className="statement-input-area" gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="statment-label">{`Valor em ${label}`}</div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    type="currency"
                    defaultValue={value}
                    onChange={async (value) => {
                      const statementId = statementsValues.findIndex(({ id }) => id === index);
                      const all = statementsValues;
                      all[statementId].value = value;
                      setStatementsValues(all);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </Card>

          <br />
          <br />
          <br />

          <UploadCard
            id="revenue-statement-upload"
            accept="application/pdf, .pdf"
            actualFile={statementsFilesAlreadyAdded}
            multiples
            alert={alertMessage}
            label="Faturamento dos últimos 24 meses"
            validateSignature
            onCompleted={async ({ id }) => {
              setStatementsFilesIds((ids) => [...ids, id]);
            }}
            onDelete={async ({ id }) => {
              setStatementsFilesIds((ids) => ids.filter((index) => index !== id));
            }}
          />
        </Spin>
      </Drawer>

      <Modal
        visible={alertStatementModal}
        okButton={{
          text: 'Voltar e preencher',
          onClick: () => setAlertStatementModal(false),
        }}
        title="OPS!!!"
        closable={false}
      >
        <p>
          Parece que você não preencheu todos os campos necessários para continuar sua solicitação. Confira se {isBadesul ? 'os últimos 7' : 'todos os'}  meses de faturamento foram preenchidos corretamente e clique em CONFIRMAR.
          <br />
          <br />
          Lembrete: Para ter acesso ao crédito as empresas precisam ter, no mínimo, sete meses de faturamento.
          <br />
          <br />
          Em caso de dúvida, peça apoio ao Suporte.
        </p>
      </Modal>
    </>
  );
};

export default ByAnnualDrawer;
