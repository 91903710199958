import React, { useEffect, useState } from 'react';
import {
  Card,
  Footer,
  Information,
  SubHeader,
} from 'components';
import {
  Layout,
  Slider,
  Progress,
} from 'components/sdk';
import {
  Col,
  Row,
  Tooltip,
} from 'antd';
import { formatAndNotificateError, formatString, interestRateByPortfolio } from 'utils';
import { useMutation } from '@apollo/client';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { SAVE_LOAN, SAVE_LOAN_EVENT } from 'screens/PreApprovedScreen/graphql';

const { Content } = Layout;

const BadescPreApproved = () => {
  const {
    me,
    currentContext,
    contextLoading,
    contextRefetch,
  } = useBusinessLoanContext();

  const [chosenAmount, setChosenAmount] = useState(20000);
  const [goingToNextStep, setGoingToNextStep] = useState(false);
  const preApprovedOption = currentContext.loan.preApprovedOptions
    .find((option) => option.installmentsCount === 36);

  const [saveLoan, { loading: saveLoading }] = useMutation(SAVE_LOAN, {
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: () => {
      contextRefetch();
      setGoingToNextStep(true);
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const handleConfirmModalSubmit = async () => {
    await saveLoan({
      variables: {
        id: currentContext?.loan.id,
        paymentDay: 15,
      },
    });

    await saveLoanEvent({
      variables: {
        loanId: currentContext?.loan.id,
        amount: chosenAmount,
        statusValue: 'pending',
        installmentsCount: 36,
      },
    });
  };
  useEffect(() => {
    if (preApprovedOption) {
      setChosenAmount(preApprovedOption.finalAmount);
    }
  }, [preApprovedOption]);

  const loading = saveLoading || saveLoanLoading || contextLoading || goingToNextStep;

  return (
    <Layout id="pre-approved-screen">
      <SubHeader
        title={`Olá, ${me?.firstName}! Veja abaixo um resumo da sua solicitação.`}
      />

      <Content className="badesc-pre-approved-content">
        <Card className="pre-approved-card">
          <Row
            gutter={[56, 26]}
            align="middle"
            justify="space-around"
            className="badesc-pre-approved-card-content"
          >
            <Col xs={24} sm={24} md={14}>
              <Row>
                <Col span={24} className="pre-approved-progress-container">
                  <Progress
                    className="pre-approved-progress"
                    type="circle"
                    percent={100}
                    strokeColor="var(--primary-button-color)"
                    strokeWidth={9}
                  />

                  <div className="pre-approved-progress-content">
                    <p className="no-margin-bottom pre-approved-progress-label secondary-text">
                      Valor
                      <br />
                      disponível
                    </p>

                    <h5>
                      <strong>
                        {(formatString(preApprovedOption.finalAmount, 'currency'))?.split('.')[0]} mil
                      </strong>
                    </h5>
                  </div>
                </Col>

                <Col span={24}>
                  <h5>
                    <b>
                      Valor solicitado
                    </b>
                  </h5>

                  <Slider
                    tooltipVisible={false}
                    min={20000}
                    max={Math.min(preApprovedOption.finalAmount, 150000)}
                    value={chosenAmount}
                    onChange={(e) => setChosenAmount(e)}
                    step={1000}
                    showValues
                    valuesFormatter={(e) => formatString(e, 'currency')}
                    input={{ type: 'currency' }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={10}>
              <Row align="middle" gutter={[16, 20]}>
                <Col span={24}>
                  <Information
                    loading={loading}
                    label="Total pré-aprovado"
                    value={formatString(preApprovedOption.finalAmount, 'currency')}
                    type=""
                  />
                </Col>
                <Col span={24}>
                  <Information
                    loading={loading}
                    label="Taxa"
                    value={(
                      <div>
                        <Tooltip title="Variação diária da taxa Selic">
                          Selic* +
                          {' '}
                          {interestRateByPortfolio[currentContext?.loan.portfolioName]} a.a.
                        </Tooltip>
                      </div>
                    )}
                  />
                </Col>
                <Col span={24}>
                  <Information
                    loading={loading}
                    label="Parcelas"
                    value={`${currentContext?.loan.portfolioName === 'Pronampe Inovação'
                      ? '12 parcelas mensais de carência + 36 parcelas mensais de amortização'
                      : '4 trimestrais de carência e 36 mensais de amortização'}`}
                  />
                </Col>
                <Col span={24}>
                  <Information
                    loading={loading}
                    label="Vencimento"
                    value="Todo dia 15"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Content>

      <Footer
        loading={loading}
        okButton={{
          text: 'Finalizar ajustes',
          loading,
          onClick: () => { handleConfirmModalSubmit(); },
        }}
      />
    </Layout>
  );
};

export default BadescPreApproved;
