import {
  notification,
} from 'antd';
import { useMemo } from 'react';

export * from './links';
export * from './texts';
export * from './validators';
export * from './files';

const crypto = require('crypto');

const createHash = (rawData) => rawData
  && crypto
    .createHash('md5')
    .update(rawData.toString())
    .digest('hex')
    .toString();

function CPFValidator(strCPF) {
  let soma = 0;
  let resto;
  if (strCPF === '00000000000') return false;
  if (strCPF === '11111111111') return false;
  if (strCPF === '22222222222') return false;
  if (strCPF === '33333333333') return false;
  if (strCPF === '44444444444') return false;
  if (strCPF === '55555555555') return false;
  if (strCPF === '66666666666') return false;
  if (strCPF === '77777777777') return false;
  if (strCPF === '88888888888') return false;
  if (strCPF === '99999999999') return false;
  for (let i = 1; i <= 9; i += 1) {
    soma += Number(strCPF.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== Number(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i += 1) soma += Number(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== Number(strCPF.substring(10, 11))) return false;
  return true;
}

const formatAndNotificateError = (error, description) => {
  let { message } = error;
  message = message.replace('GraphQL error: ', '');
  message = message.replace('ParseError: 141 ', '');

  if (message.includes('400')) return;
  notification.error({ message, description, closeIcon: null });
};

const formatError = (error) => {
  let { message } = error;
  message = message.replace('GraphQL error: ', '');
  message = message.replace('ParseError: 141 ', '');

  if (message.includes('400')) return 'Tente novamente mais tarde';
  return message;
};

const formatCPF = (cpf) => (cpf && cpf.toString().replace(/[^\d]/g, '').length === 11
  && cpf.toString().replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'))
  || '';

const formatDate = (date, utc) => {
  const aux = ((utc === undefined || utc === null) || utc === true) ? new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ) : date;
  const options = {
    day: 'numeric', month: 'numeric', year: 'numeric',
  };
  return aux.toLocaleDateString('pt-BR', options);
};

const states = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

const interestRateByPortfolio = {
  'Pronampe Mulher': '9,98%',
  'Pronampe Geral': '9,98%',
  'Pronampe Inovação': '7,55%',
  'Pronampe Emergencial CP': '6%',
  'Pronampe Emergencial SE': '6%',
};

const maskEmail = (email) => {
  let maskid = '';
  const myemailId = email;
  const prefix = myemailId.substring(0, myemailId.lastIndexOf('@'));
  const postfix = myemailId.substring(myemailId.lastIndexOf('@'));

  for (let i = 0; i < prefix.length; i += 1) {
    if (i < 3 || i > prefix.length - 4) {
      maskid += prefix[i].toString();
    } else {
      maskid += '*';
    }
  }
  maskid += postfix;
  return maskid;
};

const maskPhoneNumber = (number, { start = 7, end = 11 } = {}) => {
  const values = number.split('');

  for (let x = 0; x < values.length; x += 1) {
    if (x >= start && x <= end) values[x] = '*';
  }

  return values.join('');
};

const maskString = (text, reverse) => {
  const values = text.split('');
  // eslint-disable-next-line no-plusplus
  const iterator = reverse ? Math.floor(((values.length / 5) * 2)) + 1 : 0;
  const iterations = reverse ? values.length : Math.floor(((values.length / 5) * 2) + 1);
  for (let x = iterator; x < iterations; x += 1) {
    values[x] = '*';
  }
  return values.join('');
};

const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
  .format(value);

/**
 * @typedef {'cnpj'|'cpf'|'cep'|'rg'|'currency'|'phone'} FormatTypes
 *
 * @param {string | number} value - Valor a ser formatado
 * @param {FormatTypes} type - Typo de formatação
 * @returns {string}
 */
const formatString = (value, type) => {
  if (!value) {
    return '-';
  }

  let result = value;
  // eslint-disable-next-line default-case
  switch (type) {
    case 'cnpj': result = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'); break;
    case 'cpf': result = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); break;
    case 'cep': result = value.replace(/^(\d{5})(\d{3})/, '$1-$2'); break;
    case 'rg': result = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'); break;
    case 'phone': result = value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); break;
    case 'currency': result = value
      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }
  return result;
};

/**
 *
 * @param {Number} value - Valor que será acrescido pelas porcentagens
 * @param {Array.<Number>} percentages - Porcentagens a serem aplicadas
 * @returns {Number}
 */
const applyPercentage = (value, percentages) => {
  let amount = value;
  for (const percentage of percentages) {
    const percentageAmount = value * (percentage / 100);
    amount += percentageAmount;
  }
  return amount;
};

/**
 * @param {string} month
 * @returns {string}
 */
const translateMonth = (month) => {
  const months = {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro',
  };

  return months[month];
};

/**
 * @typedef {'years'|'months'|'weeks'|'days'|'hour'|'minutes'|'seconds'} Interval
 * @param {Date} date1
 * @param {Date} date2
 * @param {Interval} interval
  */
const dateDiff = (date1, date2, interval) => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  const timeDiff = date2 - date1;
  const dateDiff = new Date(timeDiff);

  switch (interval) {
    case 'years': return Math.abs(dateDiff.getUTCFullYear() - 1970);
    case 'months': return ((date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth()));
    case 'weeks': return Math.floor(timeDiff / week);
    case 'days': return Math.ceil(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));
    case 'hours': return Math.floor(timeDiff / hour);
    case 'minutes': return Math.floor(timeDiff / minute);
    case 'seconds': return Math.floor(timeDiff / second);
    default: return undefined;
  }
};
const getBusinessName = (business) => business?.name || business?.legalName || 'EMPRESA SEM NOME';

/**
 *  Automaticamente bota todos os valores do object `values` no array de dependências e
 *  retorna o mesmo momoizado
 *
 *  memoizeValues({foo, bar})
 *  é igual a:
 *  useMemo(() => {foo, bar}, [foo, bar])
 *
 * @param {T} values
 * @returns {T}
 * @template T
 */
const memoizeValues = (values) => useMemo(() => values, Object.values(values));

const truncateText = (text, length = 30) => {
  if (text && text.length > length) {
    return `${text.substring(0, length)}...`;
  }

  return text;
};

const badescPortfolios = ['Pronampe Emergencial SE', 'Pronampe Emergencial CP', 'Pronampe Geral', 'Pronampe Mulher', 'Pronampe Inovação'];
const badesulPortfolios = ['Badesul Microcrédito', 'Reconstrução RS', 'BADESUL Enquadramento'];

export {
  createHash,
  formatAndNotificateError,
  formatError,
  formatCPF,
  formatDate,
  states,
  CPFValidator,
  maskEmail,
  maskString,
  formatCurrency,
  formatString,
  applyPercentage,
  translateMonth,
  maskPhoneNumber,
  dateDiff,
  getBusinessName,
  memoizeValues,
  truncateText,
  interestRateByPortfolio,
  badescPortfolios,
  badesulPortfolios,
};
