/* eslint-disable max-len */
const termsOfUse = `Termos de Uso Plataforma Gyra+

Por favor, leia atentamente os termos e condições abaixo estipulados para que você possa acessar e usufruir da Plataforma Gyra+ (“Plataforma”) e todos os serviços disponibilizados por meio desta. A Plataforma é de propriedade e titularidade da Gyramais Tecnologia Ltda., inscrita no CNPJ/MF sob nº 27.734.451/0001-09, com sede na Avenida Farme de Amoedo, nº 76, sala 403, Ipanema, CEP 22420-020, na cidade do Rio de Janeiro, Estado do Rio de Janeiro, e e-mail falemais@gyramais.com (“Gyra+”). A Gyra+ e você são doravante denominados individualmente como “Parte” e, em conjunto, “Partes”. 

Ao ler o texto abaixo, você, livre e espontaneamente, reconhece e concorda com os termos e condições destes termos de uso (“Termos”), os quais serão aplicáveis a todas e quaisquer transações realizadas por você por meio da Plataforma. Integram os presentes Termos a Política de Privacidade e a Política de Avaliação de Crédito da Gyra+ (as quais se encontram disponíveis nos links https://faq.gyramais.com.br/politica-de-privacidade/ e https://faq.gyramais.com.br/politica-de-credito/), conforme atualizadas de tempos em tempos (“Política de Privacidade” e “Política de Avaliação de Crédito”, respectivamente). Caso você não concorde com quaisquer das disposições destes termos, por favor não realize qualquer transação por meio da plataforma. 

Objetivos da Plataforma e Fluxo Transacional 

A Plataforma é um sistema criado e desenvolvido para facilitar o acesso pelos usuários (“Usuários”) de serviços ofertados por instituições financeiras parceiras da Plataforma. A Plataforma não garante a conclusão das transações pretendidas pelo Usuário.

Os Usuários são pessoas físicas ou jurídicas que, por meio da Plataforma, encaminharão à Gyra+ uma solicitação para adquirir serviços ou produtos financeiros. A Gyra+ fará a análise de crédito destes Usuários e, desde que atendidos os requisitos previstos nestes Termos, na Política de Privacidade e na Política de Avaliação de Crédito, encaminharão a solicitação para a instituição financeira parceira, que avaliará os dados do Usuário e sua solicitação de crédito. 

Caso determinada solicitação de crédito de um Usuário seja aceita pela instituição financeira, a Plataforma disponibilizará uma ou mais Cédulas de Crédito Bancário (“CCB”), que será(ão) emitida(s) pelo respectivo Usuário em favor da instituição financeira. O Usuário e a instituição financeira assinarão eletronicamente a CCB e o desembolso dos recursos será realizado pela instituição financeira na conta corrente indicada pelo Usuário. Após a assinatura da CCB, a instituição financeira irá prontamente endossar tal(is) CCB para uma securitizadora de créditos financeiros parceira da Gyra+: a companhia securitizadora de creditos financeiros vert-gyra (“Securitizadora”). Após o endosso da(s) CCB à Securitizadora, esta se tornará o único e exclusivo credor do Usuário com relação à(s) CCB. 

O Usuário declara-se plenamente ciente e de acordo com o fato de que serão geradas 2 (duas) vias de cada uma das CCB por ele emitidas, as quais possuirão igual teor, conteúdo e forma, sendo que 1 (uma) via será identificada pela Gyra+ como “negociável” e 1 (uma) via como “não negociável”, nos termos do parágrafo 3º, artigo 29, da Lei nº 10.931, de 02 de agosto de 2004. 

O Usuário reconhece e concorda que a Gyra+ não é instituição financeira e não figura como parte nas negociações ou transações de recursos entre usuário e instituição financeira. A responsabilidade por todas as obrigações decorrentes dessas atividades será exclusivamente dos próprios Usuários, da instituição financeira e de eventuais terceiros envolvidos na negociação e na transação. 

Por não figurar como parte nas transações realizadas por meio da Plataforma, a Gyra+ não pode obrigar o usuário, a instituição financeira ou terceiros a honrarem com suas respectivas obrigações. 

Cadastro 

Os serviços da Plataforma só podem ser utilizados por pessoas legalmente capazes e devidamente representadas. 

Concretização da Transação 

Uma vez ocorrida a concretização da transação, de acordo com estes Termos, o Usuário reconhece e concorda que estará automaticamente vinculado às condições da(s) respectiva(s) CCB emitida(s) em favor da instituição financeira (e posteriormente endossada(s) à Securitizadora), estando sujeito às multas e penalidades ali estipuladas, sem limitação de outras multas e penalidades estabelecidas na Plataforma e nestes Termos, conforme aplicável. 

Restrições 

Os Usuários devem usar a Plataforma apenas com o objetivo de solicitar serviços e produtos financeiros junto às instituições financeiras parceiras da Gyra+, sendo expressamente vedado aos mesmos: 

(i) interferir indevidamente nas transações de outros Usuários ou quaisquer outros agentes da Plataforma; 

(ii) agredir, ofender, intimidar, assediar, caluniar, injuriar ou difamar outros Usuários, a Gyra+ ou quaisquer terceiros a estes relacionados; 

(iii) fraudar, trapacear ou praticar qualquer ato que possa prejudicar outros Usuários, a Gyra+ ou quaisquer terceiros a estes relacionados ; 

(iv) depreciar, prejudicar, difamar ou ofender a honra ou a imagem de outros Usuários, da Gyra+ ou de quaisquer terceiros a estes relacionados; 

(v) transmitir imagens, mensagens ou qualquer tipo de conteúdo de cunho pornográfico, pedófilo, racista, homofóbico, violento ou ainda ofensivo à honra, à vida privada, à imagem, à intimidade pessoal e familiar, ou de qualquer maneira ofensivo ou contrário às leis, à ordem, à moral e aos bons costumes; 

(vi) disseminar ou tentar enviar vírus de computador, incluindo, mas não se limitando a, cavalos de tróia, worms, keyboard loggers, bombas relógio, spywares, adwares, cancelbots ou qualquer outro código ou programa invasivo ou malicioso; 

(vii) disseminar ou tentar enviar spams ou qualquer outro material que possa modificar, interromper ou interferir no funcionamento, operação ou manutenção da Plataforma, das redes de comunicação, ou, de qualquer forma, prejudicar o uso e a qualidade dos serviços oferecidos pela Plataforma; 

(viii) utilizar a Plataforma para outros fins que não os expressamente previstos nestes Termos; 

(ix) copiar, adaptar ou modificar, ou tentar copiar, adaptar ou modificar, os códigos-fonte e software da Plataforma, fazer ou tentar fazer engenharia reversa, descompilação, modificação nos códigos-fonte e objeto do software da Plataforma, ou, de qualquer outra forma, violar os direitos de propriedade intelectual da Gyra+; 

(x) enviar ou tentar disseminar materiais que passivamente ou ativamente coletem informações, como gifs, web bugs, cookies e spywares, entre outros; 

(xi) utilizar dispositivo, software ou outro recurso que interfira nas atividades e transações realizadas por meio da Plataforma, bem como nos seus conteúdos, descrições, contas ou seus bancos de dados; 

(xii) usar, desenvolver ou lançar sistemas automáticos que possam acessar a Plataforma, como spiders, robots, scrapers ou leitores off-line, bem como acessar a Plataforma por meio destes sistemas; 

(xiii) tentar obter o cadastro de outros Usuários por meio da Plataforma ou por meio de qualquer técnica ou sistema; 

(xiv) remover, esconder ou prejudicar qualquer nota, banner ou signo distintivo ou marca da Plataforma e/ou da Gyra+; 

(xv) utilizar ferramentas ou proxy anônimo com a finalidade de tornar seu endereço de IP (Protocolo de Internet) anônimo; 

(xvi) interferir ou violar qualquer sistema de segurança da Plataforma, especialmente aqueles com a finalidade de limitar e controlar o acesso e uso da Plataforma e seus respectivos conteúdos; 

(xvii) solicitar informações de acesso de conta pertencente a outro Usuário; 

(xviii) utilizar, acessar e/ou divulgar os dados de outros Usuários e/ou quaisquer outros agentes da Plataforma fora do âmbito da Plataforma ou das finalidades previstas nestes Termos; 

(xix) utilizar de qualquer expediente para frustrar os serviços prestados por meio da Plataforma, desviar Usuários da Plataforma, ou fraudar a expectativa de remuneração da Plataforma pelos serviços disponibilizados; 

(xx) utilizar a Plataforma para fins comerciais, ou realizar qualquer propaganda de qualquer produto ou serviço por intermédio dessa, sem a prévia e expressa autorização da Gyra+; 

(xxi) fazer-se passar por outra pessoa, ou utilizar a Plataforma em benefício de terceiros; e 

(xxii) prestar informações falsas, incompletas, imprecisas e desatualizadas em seus dados cadastrais. 

A Gyra+ poderá rejeitar ou cancelar o cadastro de qualquer Usuário que, a critério da Gyra+, deixe de atender o disposto nestes TERMOS ou possa estar fornecendo informações falsas, incompletas ou imprecisas. 

A critério da Gyra+, não será permitida a criação de novos cadastros por Usuário que tenha tido seu cadastro cancelado por violação destes TERMOS. 

A Gyra+ poderá exigir a confirmação da veracidade, exatidão e atualização das informações. 

Obrigações dos Usuários 

Sem prejuízo de outras obrigações constantes destes Termos, constituem deveres do Usuário: 

(i) responsabilizar-se perante as instituições financeiras parceiras da Gyra+ por todas as informações e dados apresentados à Gyra+ na Plataforma, por intermédio da Plataforma ou por qualquer outra forma; 

(ii) responsabilizar-se por todas as atividades que ocorram em seu cadastro de Usuário na Plataforma, devendo imediatamente relatar à Gyra+ todas e quaisquer irregularidades ou problemas relacionados à operação da Plataforma, bem como acerca de qualquer uso não autorizado da Plataforma ou qualquer outra violação de segurança; 

(iii) certificar e garantir que as solicitações de crédito enviadas por meio da Plataforma sejam cumpridas nos prazos e condições nelas estipulados; e 

(iv) responsabilizar-se pela proteção e confidencialidade de seu login e senha de Usuário.

Responsabilidades 

O Usuário reconhece expressamente que a Gyra+ não é responsável por nenhum problema técnico e/ou operacional, incluindo falhas, perda de informação, suspensões, interrupções e qualquer outra falha de desempenho da Plataforma, nem oferece nem nunca ofereceu qualquer tipo de garantia aos Usuários ou a qualquer terceiro a respeito da utilização da Plataforma. O Usuário ainda reconhece expressamente que a Plataforma utiliza softwares, serviços e conteúdos de terceiros devidamente licenciados à Gyra+, mas que de nenhuma forma são de sua responsabilidade. 

A Gyra+ não poderá ser responsabilizada por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema ou de informações do Usuário ou de terceiros. A Gyra+ não garante a ausência de vírus ou qualquer outro elemento eletrônico nocivo, que possa danificar dados, informações ou o funcionamento de outros computadores, impedindo o uso normal da rede, do sistema ou dos equipamentos de terceiros. Neste sentido, a Gyra+ se exime de qualquer responsabilidade pelos danos e consequentes prejuízos de qualquer natureza decorrentes da presença de vírus ou outro código, arquivo ou programa recebido. 

O Usuário reconhece e concorda que a Plataforma e seu conteúdo não estarão isentos de erros, e não há qualquer garantia explícita ou implícita de que seu uso será adequado ao objetivo particular dos Usuários. Ademais, embora a Gyra+ faça esforços razoáveis para incluir informações precisas e atualizadas na Plataforma, a Gyra+ não garante ou afirma sua precisão e atualidade das informações disponibilizadas por meio da Plataforma, não sendo responsável por referidas informações e tampouco pelo uso ou destinação que o Usuário vier dar às mesmas. 

A utilização dos serviços disponibilizados por meio da Plataforma é feita sob inteira responsabilidade do Usuário. Em geral, os serviços disponibilizados por meio da Plataforma dependem da funcionalidade de vários fatores, como a interação de servidores e serviços de telecomunicações de terceiros e a adequação dos equipamentos do Usuário e de terceiros, entre outros. A Gyra+ empregará seus melhores esforços para assegurar que a Plataforma funcione adequadamente, sem interferências. No entanto, a Gyra+ não garante que a Plataforma estará disponível de forma ininterrupta ou livre de quaisquer erros ou falhas e tampouco assegura a impossibilidade do seu sistema e servidores poderem ser de qualquer forma atacados, invadidos, modificados ou lesados por terceiros. 

A Gyra+ não será responsável pelo efetivo adimplemento das obrigações assumidas entre os usuários, a instituição financeira e demais agentes relacionados à plataforma. 

A Plataforma intermedia a simulação, cotação e contratação de produtos e serviços de instituições financeiras parceiras e o seu objetivo é apenas auxiliar os Usuários. Em nenhuma hipótese a gyra+ será responsável por qualquer dano e/ou prejuízo (incluindo, sem limitação, qualquer lucro cessante) que o Usuário possa sofrer devido às transações realizadas por meio da Plataforma. A Gyra+ não garante ao usuário nenhum sucesso de aprovação ou contratação de sua proposta de crédito. 

A Gyra+ não se responsabiliza por dados ou informações fornecidas pelos Usuários, sendo o Usuário exclusivamente responsável por todo e qualquer conteúdo, material ou informação que venha a fornecer, inserir, divulgar, publicar ou transmitir para o acesso, cadastro e/ou uso da Plataforma, tampouco pelo conteúdo das mensagens trocadas por eles através da Plataforma. A Gyra+ reserva-se, o direito de eliminar as mensagens com conteúdo contrário às normas estabelecidas nestes Termos. O Usuário é o único responsável por realizar back-up de suas informações inseridas ou constantes da Plataforma. 

A Plataforma é um sistema interativo disponibilizado aos Usuários. A Gyra+ não necessariamente fiscaliza todos os conteúdos divulgados por meio da Plataforma. Assim, fica excluída toda e qualquer responsabilidade por condutas ou conteúdos dos Usuários, sendo disponibilizados meios de denúncia pelos quais poderão ser reportados abusos e violações de direitos. O Usuário não deverá publicar nenhuma informação pessoal em eventuais áreas interativas e/ou comunitárias da Plataforma. 

A Gyra+ não é nem será responsável (i) pela qualidade da conexão do computador do Usuário à internet em qualquer circunstância, (ii) pela disponibilidade ou não dos serviços de acesso à internet prestados por terceiros, (iii) pela impossibilidade de utilização de quaisquer dos serviços acima mencionados em razão da incompatibilidade destes com a configuração técnica do computador do Usuário, (iv) pela desobediência, por parte do Usuário ou terceiros, dos termos e políticas da Gyra+, (v) por quaisquer danos sofridos pelo Usuário em razão da utilização da Plataforma, ou (vi) pela reparação de danos de qualquer natureza causados pela quebra de privacidade ou segurança de dados do Usuário durante a conexão de seu computador à internet. 

A Gyra+ se exime e se exonera, em toda a extensão permitida pela legislação vigente, de qualquer responsabilidade por perdas, danos indiretos, lucros cessantes e danos emergentes, que possam decorrer da falta de continuidade ou funcionamento da plataforma. 

A Gyra+ não poderá ser responsabilizada, civil ou criminalmente, nem tampouco estará sujeita a indenização ou ressarcimento em decorrência das avaliações de crédito realizadas no âmbito da Plataforma. 

O Usuário declara-se ciente de que a metodologia utilizada pela Gyra+ para avaliação de crédito do Usuário no âmbito da Plataforma tem caráter confidencial e é de propriedade exclusiva da Gyra+, razão pela qual não serão disponibilizadas ao Usuário e ao público em geral informações acerca da referida metodologia além daquelas indicadas na Política de Avaliação de Crédito e em nosso site. 

A Gyra+ obriga-se a tratar como confidenciais os dados pessoais do Usuário que permitam a sua identificação, de acordo com sua Política de Privacidade. Todavia, o Usuário reconhece e aceita que a Gyra+ não tem como assegurar o sigilo e a confidencialidade de toda e qualquer informação fornecida e/ou divulgada pelo Usuário na Plataforma ou por intermédio da Plataforma ou de qualquer outra forma, tais como, sem limitação, envio de dúvidas e/ou sugestões. 

Em qualquer hipótese, a responsabilidade da Gyra+ decorrente destes termos será limitada aos danos diretos comprovadamente sofridos pelo usuário em razão de qualquer descumprimento das obrigações da Gyra+, limitados ao valor devido ou pago pelo usuário à Gyra+ cuja relação tenha causado o dano, excluídos todos e quaisquer pleitos por danos indiretos (incluindo por lucros cessantes e perda de dados ou oportunidade de negócios) sofridos pelo usuário ou por qualquer terceiro decorrentes do uso ou funcionamento da plataforma. 

O Usuário é exclusivamente responsável por suas interações com outros Usuários e quaisquer outros agentes relacionados à Plataforma. 

Remuneração da Plataforma 

Pelo uso da Plataforma e pelos serviços prestados de avaliação de crédito contratados via Plataforma, por iniciativa dos Usuários, para o encaminhamento de solicitação de crédito, uma vez concretizada a transação, os Usuários concordam em pagar à Gyra+: (i) a taxa equivalente até 4% (quatro por cento) do valor do crédito solicitado; e (ii) o equivalente até 1% (um por cento) do valor solicitado à Instituição financeira credora. 

Os valores (i) e (ii) serão pagos pelos Usuários quando da aprovação do crédito pela Instituição Financeira. Os Usuários desde já autorizam a Instituição Financeira a deduzir os percentuais e taxas previstas nos itens (i) e (ii) acima dos valores a serem efetivamente creditados aos usuários, por conta e ordem e a pedido destes, sem prejuízo dos tributos e despesas devidas e indicados na própria CCB. 

O USUÁRIO AUTORIZA A INSTITUIÇÃO FINANCEIRA A DEDUZIR DOS VALORES A SEREM EFETIVAMENTE REPASSADOS AO USUÁRIO, POR CONTA E ORDEM E A PEDIDO DO USUÁRIO, OS TRIBUTOS E DESPESAS INDICADOS NA PRÓPRIA CCB, BEM COMO OS PERCENTUAIS PREVISTOS NO ITENS (i) E (ii) ACIMA. 

Serviços de Proteção ao Crédito 

O Usuário reconhece e concorda que, em caso de inadimplência, poderá ser inscrito nos serviços de proteção ao crédito, sofrer protesto ou execução na esfera judicial. o usuário reconhece e concorda que o inadimplemento de suas obrigações, conforme estabelecidas na CCB, poderá ensejar o registro da dívida junto aos órgãos de proteção ao crédito e entidades pertinentes, incluindo, sem limitação, boa vista serviços s.a., serasa s.a. e outros bureaus de crédito similares, os quais comunicarão tal fato à parte inadimplente por e-mail e/ou carta com aviso de recebimento para os endereços então cadastrados na plataforma, de acordo com a legislação aplicável. 

Fica desde já estabelecido que o usuário inadimplente deverá reembolsar quaisquer despesas incorridas pela Gyra+ em face das providências mencionadas acima, sendo que, da mesma forma, poderá solicitar à Gyra+ o reembolso de eventuais despesas por este indevidamente incorridas em relação à sua CCB ou documentos correlatos. 

Sites de Terceiros 

A Plataforma poderá fornecer links para outros sites da internet ou outros recursos. Estes links serão disponibilizados como uma conveniência aos usuários e, como a Gyra+ não tem controle sobre tais sites ou recursos externos, o usuário reconhece e concorda que a Gyra+ não é responsável e não endossa ou se responsabiliza por qualquer conteúdo, propaganda, produtos, serviços, assim como pelos termos de uso e políticas de privacidade desses sites ou outros materiais contidos ou disponibilizados por intermédio dos mesmos. Neste sentido, sugerimos que o usuário se informe sobre os termos de uso, políticas de privacidade e demais documentos que possam reger seu acesso ou utilização de sites de terceiros antes de acessar, fornecer informações ou utilizar quaisquer recursos nesses ambientes. O usuário expressamente reconhece e concorda que a Gyra+ não será responsável, direta ou indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados por, ou em conexão com, as informações, bens e serviços disponíveis em, ou através de, tais sites de terceiros. 

Indenização 

O Usuário deverá manter a Gyra+, seus sócios, diretores, administradores, colaboradores, representantes e empregados, indenes de quaisquer danos, despesas, custos, responsabilidades, obrigações, defeitos, prejuízos, reclamações e despesas, diretos e/ou indiretos, impostas à Gyra+, resultantes ou decorrentes de: 

(i) qualquer ação do Usuário, seus conselheiros, diretores, empregados ou agentes em relação ao uso da Plataforma; 

(ii) qualquer declaração enganosa, violação de declaração ou garantia ou descumprimento de qualquer avença ou acordo do Usuário; ou 

(iii) qualquer reivindicação com base em suposta violação, no todo ou em parte, pelo Usuário, de quaisquer direitos de terceiros, incluindo em relação ao seu conteúdo inserido na Plataforma e às suas obrigações assumidas perante a instituição financeira e terceiros em geral. 

O Usuário deverá reembolsar a Gyra+, seus sócios, diretores, administradores, colaboradores, representantes e empregados por todos e quaisquer danos, prejuízos, despesas ou custos incorridos em razão dos atos acima mencionados, no prazo de 10 dias úteis contados de recebimento de notificação nesse sentido. 

Infrações de Terceiros 

Na hipótese de qualquer Usuário considerar que a Plataforma esteja sendo utilizada de forma indevida por qualquer terceiro, deverá enviar uma comunicação à Gyra+, através do endereço eletrônico contato@gyramais.com, fornecendo os seguintes dados: 

(i) dados pessoais do Usuário responsável pela comunicação, tais como nome, endereço, número de telefone e endereço de correio eletrônico; 

(ii) especificação da suposta atividade irregular ocorrida na Plataforma, incluindo, mas não se limitando, a descrição dos fatos ou circunstâncias que revelam o caráter irregular de tal atividade; 

(iii) identificação precisa de onde a violação pode ser encontrada na Plataforma, com a respectiva URL; e 

(iv) declaração expressa, clara e sob a responsabilidade do Usuário responsável pela comunicação de que a informação contida na comunicação está correta e precisa. 

Uma vez recebida tal comunicação, a Gyra+, a seu exclusivo critério e sem necessidade de notificação, reserva-se o direito de retirar o conteúdo supostamente infrator das políticas da Plataforma ou excluir os Usuários infratores das políticas da plataforma. 

Conteúdo da Plataforma e Propriedade Intelectual 

O Usuário reconhece e concorda que todas as informações e conteúdos disponibilizados na Plataforma pela Gyra+ são de propriedade ou foram devidamente licenciados à Gyra+ e, por isso, não poderão ser disponibilizadas a terceiros, exceto quando expressamente autorizado pela Gyra+.

O Usuário concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar com ou sem finalidade comercial qualquer parte das informações e conteúdos da Plataforma (incluindo, sem limitação, a(s) CCB), bem como se obriga a utilizar todo o conteúdo da Plataforma de forma lícita, sendo vedada a reprodução, distribuição, transformação, comercialização ou modificação do conteúdo sem a prévia e expressa autorização da Gyra+, bem como o fornecimento de suas informações de acesso à Plataforma (link disponibilizado pela Gyra+) para terceiros não cadastrados. 

Ao aceitar estes Termos, o Usuário concede à Gyra+ e seus parceiros, uma licença gratuita, perpétua e irrevogável, para o uso de todo e qualquer conteúdo, foto ou imagem postado na Plataforma, de acordo com os fins estabelecidos nestes Termos e na Política de Privacidade. 

O Usuário ainda declara, garante e concorda que todo e qualquer conteúdo, foto ou imagem inserido na Plataforma pelo Usuário não viola qualquer direito de terceiro, incluindo, sem limitação, qualquer direito autoral, marca, direitos de privacidade e quaisquer outros direitos, além de não conterem qualquer conteúdo ilícito ou imoral. 

O Usuário reconhece e aceita que a Plataforma e os serviços e materiais disponibilizados através da Plataforma constituem e/ou incorporam patentes, marcas, programas de computador, direitos autorias e demais propriedade industrial, segredos de empresa e/ou informações confidenciais que são e permanecerão de titularidade exclusiva da Gyra+, os quais são protegidos pela legislação local e pelos tratados internacionais relativos à propriedade intelectual.

Estes Termos não contemplam nenhuma cessão ou transferem ao Usuário a titularidade dos direitos relacionados à propriedade intelectual da Gyra+

Declarações 

Cada Parte declara e garante à outra que as afirmações a seguir são verdadeiras em relação à própria Parte na formalização destes Termos e assim permanecerão durante todo o período de relacionamento entre as Partes decorrente da utilização da Plataforma: 

(i) cada Parte possui plena capacidade e legitimidade para celebrar esses Termos, realizar todas as transações aqui previstas e cumprir todas as obrigações aqui assumidas; 

(ii) a celebração destes Termos e o cumprimento das obrigações pelas Partes (a) não violam qualquer disposição contida nos seus documentos societários, quando aplicável, (b) no seu melhor conhecimento, não violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva Parte esteja vinculada, (c) estão devidamente autorizadas, nos termos dos seus atos constitutivos em vigor, quando aplicável, a praticar todos os atos aqui previstos, e (d) não constituem violação a, nem estão em conflito com, qualquer contrato do qual seja parte; 

(iii) estes Termos são validamente celebrados e constituem obrigação legal, válida, vinculante e exequível contra cada Parte; e 

(iv) cada Parte está apta a cumprir as obrigações ora previstas nestes Termos e agirá em relação à outra de boa-fé e com lealdade. 

Vigência e Rescisão 

Esses Termos vigorarão até o adimplemento de todas as obrigações do Usuário constantes das eventuais CCB que emitir em favor de instituições financeiras, por meio da Plataforma. 

Estes Termos poderão ser rescindidos a qualquer tempo, pela Parte adimplente, imediatamente, em caso de (i) infração pela outra Parte, de quaisquer cláusulas aqui constantes; e (ii) insolvência, pedido de falência ou recuperação judicial ou extrajudicial da outra Parte. 

Sem prejuízo do disposto nestes Termos, a disponibilização da Plataforma tem duração indeterminada e a Gyra+ reserva-se ao direito de terminar, suspender ou interromper unilateralmente e a qualquer momento, sem necessidade de aviso prévio ou posterior, a referida disponibilização, ou sua inteira transformação e modificação. 

A Gyra+ poderá advertir, suspender ou cancelar, temporária ou definitivamente, o cadastro do Usuário, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a execução destes Termos se o Usuário não cumprir qualquer dispositivo aqui previsto e demais políticas da Gyra+. A Gyra+ poderá ainda tomar tais medidas caso não possa ser verificada a identidade do usuário ou caso qualquer informação fornecida por ele esteja incorreta. Nos casos de inabilitação do cadastro do usuário, todas as transações em andamento serão automaticamente canceladas e os respectivos dados eliminados. 

Sem prejuízo de outras medidas cabíveis, a Gyra+ poderá suspender o acesso e cancelar definitivamente a conta de um Usuário, a qualquer tempo, mediante comunicação escrita com efeito imediato se: (i) a Gyra+ receber qualquer ordem judicial solicitando o cancelamento da conta do Usuário; (ii) a Gyra+ receber qualquer informação ou reclamação de outro Usuário ou de terceiros reportando o uso indevido pelo Usuário de dados ou informações pessoais de qualquer outro Usuário ou de terceiros; (iii) o Usuário deixar de cumprir qualquer disposição destes Termos de Uso e demais políticas da Plataforma. 

Fica desde já estabelecido que, em qualquer hipótese de cancelamento da utilização do Plataforma ou de qualquer dos serviços, o Usuário não terá direito a qualquer reembolso ou restituição de quantias eventualmente pagas, ficando desde já estabelecido que as disposições destes Termos que, por sua própria natureza, tenham caráter perene, sobreviverão ao cancelamento do acesso e do uso da Plataforma e dos Serviços
A Gyra+ poderá, a seu exclusivo critério, realizar o back-up das informações, mensagens ou conteúdos postados na Plataforma a fim de restaurá-los em caso de falha ou erro da Plataforma. Porém, este back-up não é obrigatório, não sendo a Gyra+ obrigada a disponibilizar tais informações, mensagens ou conteúdos a quem quer que seja. 

Validade das Transações e Recebimento de Comunicações 

O presente instrumento representa integralmente o acordado pelas Partes em relação ao seu objeto e se sobrepõe aos termos e condições de qualquer outro acordo ou documento previamente firmado a respeito destes serviços. 

Considerando que a Plataforma opera exclusivamente pela internet, é necessário que o Usuário reconheça e concorde com a validade das transações eletrônicas operadas por meio da Plataforma, incluindo, sem limitação, a aceitação destes Termos. 

Como parte das atividades realizadas pela Gyra+ por meio da Plataforma e pela instituição financeira parceira, o Usuário também precisa consentir com a divulgação eletrônica de seus dados, de acordo com estes Termos e com a Política de Privacidade. 

Ao aceitar esses Termos, o Usuário reconhece e concorda em receber eletronicamente todos os tipos de documentos, comunicações, notificações, contratos e acordos, incluindo, sem limitação, as CCB. O Usuário também autoriza a Gyra+, a instituição financeira e seus parceiros a enviar e-mails, SMS, correspondências, assim como a realizar contatos telefônicos relativos aos serviços oferecidos por meio da Plataforma e outros assuntos que podem ser do seu interesse. 

O Usuário, ao aceitar estes termos, concorda e reconhece a validade da assinatura eletrônica da Gyra+ e do próprio Usuário, nos termos do parágrafo 2º do artigo 10, da medida provisória nº 2.200, de 24 de agosto de 2001, conforme atualizada ou substituída. 

A aceitação integral destes Termos pelo Usuário constitui um contrato plenamente válido, o qual o Usuário formou, executou, registrou e validou seus respectivos termos e condições. Neste sentido, o Usuário reconhece e concorda que esses Termos e quaisquer CCB decorrentes do uso da Plataforma, na medida em que forem aceitos e assinados eletronicamente, constituem vínculos contratuais válidos para todas as finalidades aqui previstas e como tal, têm efeito legal, são válidos e exequíveis e devem ser integramente cumpridos.
 
Alterações dos Termos de Uso
 
A Gyra+ se reserva o direito de alterar os presentes Termos a qualquer momento, a seu exclusivo critério, dispensada qualquer notificação prévia ao Usuário, sendo responsabilidade do Usuário visitar essa página de tempos em tempos para permanecer informado a respeito de quaisquer alterações. Caso as alterações sejam significativas, poderemos colocar um aviso com mais destaque em nosso site, ou lhe enviar um e-mail com a nova versão dos Termos. A contínua utilização da Plataforma pelo Usuário significa sua aderência e concordância com a atual versão destes Termos. 

Política de Privacidade 

Mediante a aceitação destes Termos, o Usuário expressamente reitera sua concordância com a Política de Privacidade, permitindo assim o uso de seus dados de cadastro pela Gyra+ exclusivamente para os fins descritos na referida Política de Privacidade. 

Em caso de dúvidas sobre a proteção de seus dados ou para obter maiores informações sobre a utilização de seus dados pela Gyra+, favor consultar a página da Política de Privacidade em nosso site ou entrar em contato com nossa equipe pelo e-mail suporte@gyramais.com. 


Geral 

O não exercício ou o atraso no exercício, pelas Partes de qualquer direito constante desses Termos não deverá ser interpretado como uma renúncia ou novação. 

A proibição, invalidade ou inexequibilidade de qualquer disposição destes Termos não invalidará ou afetará os Termos como um todo, devendo permanecer em vigor todas as demais disposições que não tenham sido afetadas pela proibição, invalidade ou inexequibilidade da cláusula inoperante. 

Nenhuma das Partes poderá ceder ou transferir a qualquer terceiro, total ou parcialmente, seus direitos e obrigações decorrentes destes Termos. 

Quaisquer dúvidas, críticas, sugestões e/ou reclamações poderão ser feitas pelo e-mail falemais@gyramais.com. 

Toda comunicação entre o Usuário e a Gyra+ será feita utilizando os dados constantes do cadastro do Usuário na Plataforma. Caberá ao Usuário informar à Gyra+, por meio da interface própria da Plataforma, acerca de quaisquer alterações nas suas informações de contato. Até que seja feita esta comunicação, serão válidos e eficazes os avisos e as comunicações enviadas conforme dados de contato constantes no cadastro. 

Aceitação 

A participação na Plataforma como Usuário pressupõe o conhecimento e a aceitação integral destes Termos, da Política de Privacidade e da Política de Avaliação de Crédito. 

Caso não aceite ou não concorde com estes Termos, com a Política de Privacidade e/ou com a Política de Avaliação de Crédito, não selecione o item “aceitar os termos e condições do serviço” e encerre a sua sessão na Plataforma. 

Caso concorde e aceite estes Termos, favor manifestar sua concordância por via eletrônica selecionando o item “aceitar os termos e condições do serviço” antes de clicar na tecla “inscreva-se” e realizar a sua inscrição na Plataforma.`;

const termsOfUseBadesc = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA PRONAMPE EMERGENCIAL.

OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE EMERGENCIAL.

AO CLICAR "Li e aceito os Termos do Badesc", VOCÊ ACEITA OS TERMOS E CONDIÇÕES.

PRONAMPE EMERGENCIAL é a linha de crédito destinada a estimular a rápida reconstrução e recuperação dos empreendimentos produtivos diretamente afetados por desastres naturais, catástrofes climáticas e situações correlatas, localizados em Municípios Catarinenses em Situação de Emergência (SE) ou Estado de Calamidade Pública (ECP), devidamente reconhecidos pela Defesa Civil, visando minimizar os efeitos econômicos e sociais negativos deles decorrentes, preservando os níveis de emprego e renda nas regiões afetadas. São beneficiárias desta linha de crédito as pessoas jurídicas de micro e pequeno porte, conforme legislação Federal e nos normativos da fonte, diretamente atingidas, localizadas nos municípios catarinenses em situação de emergência ou estado de calamidade pública, homologado por decreto do Governador do Estado (conforme Resolução).

Não são passíveis de apoio financeiro no âmbito da linha PRONAMPE EMERGENCIAL (SE):

a) Pessoas Físicas;

b) Sociedades Cooperativas;

c) Setor Público;

d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);

e) Empresas constituídas há menos de 12 meses da data de solicitação dos recursos;

f) Empresas sem faturamento em 07 (sete) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;

g) Empresas inadimplentes com o BADESC;

h) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);

i) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);

j) Empresas com restrições junto à Receita Federal do Brasil;

k) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;

l) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);

m) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR);

n) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos.

O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 150.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 150.000,00 ou o correspondente a 4 (quatro) vezes o faturamento médio mensal fiscal do empreendimento, conforme apurado nos 12 (doze) meses anteriores à solicitação do financiamento - o que for menor.

A taxa de juros será de:

a) A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES e taxa fixa BNDES (TFB);

b) O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;

c) Se a empresa estiver adimplente, fará jus a equalização de 50% (cinquenta por cento) da taxa de juros, que ficará sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma prevista na Lei nº 18.096/2021 e no Decreto nº Estadual n º 1.085/2021 e suas atualizações, bem como na Medida Provisória nº 260/23 e Decreto Estadual vigente.

Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:

a) de carência: 12 meses;

b) total: 48 meses.

Os pagamentos durante a carência serão trimestrais, quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.

Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:

Referente à empresa:

a) Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;

b) Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

c) Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

d) RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;

e) Comprovante de Inscrição e de Situação Cadastral/CNPJ;

f) Cópia de folha de talão de cheque ou de extrato bancário que comprove a titularidade da conta corrente cadastrada em nome da empresa;

g) Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa);

h) Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;

i) Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;

j) Certidão Negativa de Débitos Trabalhistas;

k) Certificado de Regularidade do FGTS;

l) Declaração de Responsabilidade Social Ambiental - RSCA.

Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:

a) Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);

b) Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;

c) Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;

d) Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);

e) Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);

As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.

O envio da documentação não obriga o Badesc a conceder o crédito solicitado.

As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.

A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras.`;

const termsOfUseBadescWoman = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA DE CRÉDITO PRONAMPE MULHER. ok

OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE MULHER.

AO CLICAR NO BOTÃO "EU CONCORDO", VOCÊ CONCORDA COM OS TERMOS E CONDIÇÕES.

1. PRONAMPE MULHER é a linha destinada a incentivar o empreendedorismo feminino e promover a igualdade de gênero na economia catarinense, por meio da concessão de subsídio financeiro, pelo Estado, às pessoas jurídicas de micro e pequeno porte sediados em Santa Catarina, com participação feminina no capital social, de acordo com as orientações e normas contidos em Lei e Decreto Estadual, no sentido de que para acessar este produto, a empresa declara, desde já, que atende, conjuntamente, aos requisitos obrigatórios listados abaixo:

· Participação de sócias mulheres no capital social maior do que 50%; OU

· Participação de sócias mulheres no capital social maior ou igual a 20% e uma sócia mulher (pelo menos) como administradora.

· Não estar enquadrada ou estar pleiteando recursos em quaisquer outras das modalidades do Pronampe Santa Catarina; OU

· · Não estar pleiteando recursos nas demais modalidades do Pronampe Santa Catarina por intermédio de empresas do Grupo econômico, quando for o caso, acima do limite estipulado.

Observação: O capital social e administração da mulher transgênero atende ao requisito de participação feminina.

2. Não são passíveis de apoio financeiro no âmbito do PRONAMPE MULHER:

a) Pessoas Físicas;

b) Sociedades Cooperativas;

c) Setor Público;

d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);

e) Microempreendedoras Individuais (MEI);

f) Empresas constituídas há menos de 12 meses;

g) Empresas sem os requisitos obrigatórios previstos no item a acima, há pelo menos 3 (três) meses, contados da data da solicitação do crédito e respectivo enquadramento;

h) Empresas sem faturamento em 05 (cinco) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;

i) Empresas inadimplentes com o BADESC;

j) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);

k) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);

l) Empresas com restrições junto à Receita Federal do Brasil;

m) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;

n) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);

o) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos;

p) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR).

3. O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 100.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 100.000,00 acrescido do IOF devido ou 4 vezes o seu Faturamento Médio Mensal (FMM) apurado nos últimos 12 meses (o que for menor).

4. A taxa de juros será de:

a. A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES e taxa fixa BNDES (TFB);

b. O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;

c. Se a empresa estiver adimplente, fará jus ao subsídio financeiro integral dos encargos remuneratórios, que ficarão sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma prevista em Lei e Decreto Estadual.

5. Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:

a) de carência: não há carência mínima;

b) total: 48 meses.

6. Os pagamentos durante a carência serão trimestrais, quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.

7. Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:

a. Referente à empresa:

a. Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;

b. Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

c. Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

d. RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;

e. Comprovante de Inscrição e de Situação Cadastral/CNPJ; ok

f. Cópia de folha de talão de cheque ou de extrato bancário que comprove a titularidade da conta corrente cadastrada em nome da empresa;

g. Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa)

h. Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;

i. Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;

j. Certidão Negativa de Débitos Trabalhistas;

k. Certificado de Regularidade do FGTS;

l. Declaração de Responsabilidade Social e Ambiental – RSAC;

m. Comprovação de regularidade perante os órgãos ambientais: Licença de Operação, Certidão de dispensa ou conformidade ambiental ou Declaração de Atividade não constante emitida por órgão competente na esfera estadual ou municipal, conforme o caso.

n. Certidão Negativa de Débitos Ambientais.

b. Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:

a. Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);

b. Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;

c. Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;

d. Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);

e. Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);

8. As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.

9. O envio da documentação não obriga o Badesc a conceder o crédito solicitado.

10. As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.

11. A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras.`;

const termsOfUseBadescGeneral = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA DE CRÉDITO PRONAMPE SC.

OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE SC.

AO CLICAR NO BOTÃO "EU CONCORDO", VOCÊ CONCORDA COM OS TERMOS E CONDIÇÕES.

a. PRONAMPE SC é a linha destinada a à elevação da produtividade e da competitividade dos pequenos negócios catarinenses, caracterizados como microempresa ou empresa de pequeno porte, em conformidade com o art. 3º da Lei Complementar federal n. 123, de 2006, por meio da concessão de subsídio financeiro, pelo Estado, de acordo com as orientações e normas contidos em Lei e Decreto Estadual, no sentido de que para acessar este produto, a empresa declara, desde já, que atende, conjuntamente, aos requisitos obrigatórios listados abaixo:

· Não estar enquadrada ou estar pleiteando recursos em quaisquer outras das modalidades do Pronampe Santa Catarina; OU

· Não estar pleiteando recursos nas demais modalidades do Pronampe Santa Catarina por intermédio de empresas do Grupo econômico, quando for o caso, acima do limite estipulado.

3. Não são passíveis de apoio financeiro no âmbito do PRONAMPE SC:

a) Pessoas Físicas;

b) Sociedades Cooperativas;

c) Setor Público;

d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);

e) Microempreendedoras Individuais (MEI);

f) Empresas constituídas há menos de 12 meses;

g) Empresas sem os requisitos obrigatórios previstos no item a acima, há pelo menos 3 (três) meses, contados da data da solicitação do crédito e respectivo enquadramento;

h) Empresas sem faturamento em 05 (cinco) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;

i) Empresas inadimplentes com o BADESC;

j) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);

k) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);

l) Empresas com restrições junto à Receita Federal do Brasil;

m) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;

n) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);

o) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos;

p) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR).

4. O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 150.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 150.000,00 acrescido do IOF devido ou 4 vezes o seu Faturamento Médio Mensal (FMM) apurado nos últimos 12 meses (o que for menor). ok

5. A taxa de juros será de:

a. A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES e taxa fixa BNDES (TFB); ok O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;

b. Se a empresa estiver adimplente, fará jus ao subsídio financeiro de 40% (quarenta por cento) dos juros remuneratórios. A pleiteante somente pagará os valores correspondentes à amortização da dívida e 60%

(sessenta por cento) da taxa de juros remuneratórios previstos, ficando os 40% (quarenta por cento) restantes dos juros remuneratórios sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma do Decreto Estadual vigente.

6. Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:

a. de carência: 12 meses;

b. de amortização: 36 meses;

c. total: 48 meses.

7. Os pagamentos durante a carência serão trimestrais, quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.

8. Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:

1. Referente à empresa:

a. Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;

b. Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

c. Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

d. RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;

e. Comprovante de Inscrição e de Situação Cadastral/CNPJ;

f. Cópia de folha de talão de cheque ou de extrato bancário que comprove a titularidade da conta corrente cadastrada em nome da empresa;

g. Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa)

h. Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;

i. Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;

j. Certidão Negativa de Débitos Trabalhistas;

k. Certificado de Regularidade do FGTS;

l. Declaração de Responsabilidade Social e Ambiental – RSAC;

m. Comprovação de regularidade perante os órgãos ambientais: Licença de Operação, Certidão de dispensa ou conformidade ambiental ou Declaração de Atividade não constante emitida por órgão competente na esfera estadual ou municipal, conforme o caso.

2. Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:

a. Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);

c. Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;

d. Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;

e. Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);

f. Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);

9. As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.

10. O envio da documentação não obriga o Badesc a conceder o crédito solicitado.

11. As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.

12. A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras.`;

const termsOfUseBadescInovacao = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA DE CRÉDITO PRONAMPE INOVAÇÃO.
OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE INOVAÇÃO.
AO CLICAR NO BOTÃO "EU CONCORDO", VOCÊ CONCORDA COM OS TERMOS E CONDIÇÕES.
PRONAMPE INOVAÇÃO é a linha destinada a fomentar a inovação nos pequenos negócios catarinenses, caracterizados como microempresa ou empresa de  pequeno porte, em conformidade com o art. 3º da Lei Complementar federal n. 123, de 2006, cuja atuação se caracteriza pela inovação aplicada a modelo de negócios ou a produtos ou serviços ofertados, visando elevar a produtividade e a competitividade da economia do Estado, por meio da concessão de subsídio financeiro, pelo Estado, de acordo com as orientações e normas contidos em Lei e Decreto Estadual.
Para acessar este produto, a empresa declara, desde já, que atende, isoladamente ou conjuntamente, aos requisitos obrigatórios listados abaixo:
Declaração em seu ato constitutivo ou alterador e utilização de modelos de negócios inovadores para a geração de produtos ou serviços, nos termos do inciso IV do Caput do art. 2º da Lei Federal nº 10.973; OU
Possuir enquadramento no regime especial Inova Simples, nos termos art. 65-A da Lei Complementar nº 123.
 
Não são passíveis de apoio financeiro no âmbito do PRONAMPE INOVAÇÃO:
a) Pessoas Físicas;
b) Sociedades Cooperativas;
c) Setor Público;
d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);
e) Microempreendedoras Individuais (MEI);
f) Empresas constituídas há menos de 12 meses;
g) Empresas sem um dos requisitos obrigatórios previstos no item acima, na data da solicitação do crédito e respectivo enquadramento;
h) Empresas sem faturamento em 05 (cinco) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;
i) Empresas inadimplentes com o BADESC;
j) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);
k) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);
l) Empresas com restrições junto à Receita Federal do Brasil;
m) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;
n) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);
o) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos;
p) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR).
O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 250.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 250.000,00 acrescido do IOF devido ou 4 vezes o seu Faturamento Médio Mensal (FMM) apurado nos últimos 12 meses (o que for menor).
A taxa de juros será de:
A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES (caso a fonte de recursos seja o BNDES) e taxa fixa BNDES (TFB); 
O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;
Se a empresa estiver adimplente, fará jus ao subsídio financeiro de 50% (cinquenta por cento) dos juros remuneratórios. A pleiteante somente pagará os valores correspondentes à amortização da dívida e 50% (cinquenta por cento) da taxa de juros remuneratórios previstos, ficando os 50% (cinquenta por cento) restantes dos juros remuneratórios sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma do Decreto Estadual vigente.
Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:
de carência: 12 meses; 
de amortização: 36 meses;
total: 48 meses. 
Os pagamentos durante a carência serão prioritariamente mensais ou trimestrais (dependendo da fonte de recursos), quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.
Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:
Referente à empresa:
Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;
Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;
Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;
RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;
Comprovante de Inscrição e de Situação Cadastral/CNPJ;
Cópia de folha de talão de cheque ou de extrato bancário ou outro documento que comprove a titularidade da conta corrente cadastrada em nome da empresa;
Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa)
Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;
Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;
Certidão Negativa de Débitos Trabalhistas;
Certificado de Regularidade do FGTS;
Declaração de Responsabilidade Social e Ambiental – RSAC;
Comprovação de regularidade perante os órgãos ambientais: Licença de Operação, Certidão de dispensa ou conformidade ambiental ou Declaração de Atividade não constante emitida por órgão competente na esfera estadual ou municipal, conforme o caso.
Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:
Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);
Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;
Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;
Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);
Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);
As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.
O envio da documentação não obriga o Badesc a conceder o crédito solicitado.
As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.
A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras. `;

const privacyPolicy = `Política de Privacidade da Plataforma Gyra+
 
Recomendamos que leia nossa Política de Privacidade com atenção. Aqui descrevemos como serão tratadas as informações fornecidas por você ao acessar e utilizar a nossos serviços, por meio da Plataforma Gyra+ (“Plataforma”).

Ao ler o texto abaixo, você, livre e espontaneamente, reconhece e concorda com os termos e condições desta Política de Privacidade, os quais serão aplicáveis a todas e quaisquer transações realizadas por você por meio da plataforma. 

Caso você não concorde com quaisquer das disposições desta Política de Privacidade, por favor não realize qualquer transação por meio da Plataforma.

Plataforma Gyra+ 

A Plataforma é um sistema criado e desenvolvido para facilitar o acesso dos usuários (“Usuários”) a serviços ofertados por instituições financeiras parceiras da Plataforma. Para a prestação dos serviços fornecidos pela Plataforma, a Gyra+, dentre outras tecnologias, utilizará o sistema de “Open Banking”, definido pelo Banco Central do Brasil como o compartilhamento padronizado de dados e serviços por meio de abertura e integração de sistemas.

Ao acessar e utilizar a Plataforma, de propriedade e titularidade da Gyramais Tecnologia Ltda., inscrita no CNPJ/MF sob nº 27.734.451/0001-09, com sede na Rua Farme de Amoêdo, nº 76, , Ipanema, CEP 22240-020, na cidade do Rio de Janeiro, Estado do Rio de Janeiro, e e-mail contato@gyramais.com (“Gyra+”), o Usuário concorda que a Gyra+ terá acesso a algumas de suas informações (incluindo dados pessoais, conforme definidos pela Lei nº 13.709/18 (“LGPD”), bem como concorda com esta Política de Privacidade, autorizando o tratamento das informações (incluindo Dados Pessoais) de acordo com os termos desta Política de Privacidade, com os Termos de Uso da Plataforma (os quais se encontram disponíveis no link https://faq.gyramais.com.br/termos-de-uso/) (“Termos de Uso”) e com a Política de Avaliação de Crédito adotada pela Gyra+ (a qual se encontra disponível no link https://faq.gyramais.com.br/politica-de-credito/) (“Política de Avaliação de Crédito”). 

Esta Política de Privacidade esclarece quais de suas informações pessoais podem ser acessadas e como elas serão utilizadas e armazenadas pela Gyra+. Todas as informações que possam identificá-lo individualmente, de maneira direta ou indireta, incluindo suas informações de cadastro, dados armazenados em cookies e informações a respeito do seu acesso e utilização da Plataforma (incluindo informações de Protocolo de Internet) não serão utilizadas para outras finalidades que não as constantes desta Política de Privacidade, nos Termos de Uso da Plataforma e na Política de Avaliação de Crédito. 

A Gyra+ protege os direitos de privacidade dos Usuários, nos termos da legislação aplicável, desta Política de Privacidade, dos Termos de Uso e da Política de Avaliação de Crédito.


Cadastro 

Para ter acesso aos nossos serviços, você deverá realizar um cadastro na Plataforma, por meio de link que será disponibilizado pela Gyra+, por e-mail, para que você forneça determinadas informações, tais como: (i) dados pessoais necessários ao estrito cumprimento dos serviços prestados através da Plataforma, incluindo, mas não se limitando a, nome, endereço, telefone, e-mail, CPF e RG; (ii) dados cadastrais da pessoa jurídica contratante dos serviços oferecidos pela Plataforma, incluindo, sem limitação, razão social, endereço, CNPJ, e-mail e NIRE; e/ou (ii) demais dados corporativos e financeiros necessários para nossa análise de crédito, de acordo com nossa política de avaliação de crédito.

Limitação de Responsabilidade 

Os Usuários se responsabilizam civil e criminalmente pela veracidade dos dados fornecidos por meio da Plataforma, bem como se comprometem a atualizar seus dados sempre que houver qualquer alteração. 

A Gyra+ se exime de toda e qualquer responsabilidade pelos danos e prejuízos de qualquer natureza que possam decorrer do acesso, interceptação, eliminação, alteração, modificação ou manipulação, por terceiros não autorizados, dos dados fornecidos pelos usuários. O usuário será exclusivamente responsável pela proteção e confidencialidade de seu login e senha de usuário.


Informações Coletadas 

A Gyra+ poderá coletar e armazenar as informações abaixo com o intuito de cumprir com a finalidade dos serviços contratados pelos Usuários através da Plataforma, bem como de aperfeiçoar e desenvolver uma melhor experiência para os Usuários: 

(i) informações voluntariamente fornecidas pelo Usuário no momento do seu cadastro junto à Plataforma; 

(ii) informações do Usuário compartilhadas por plataformas de terceiros (“Plataformas de Terceiros”) com a Gyra+, sendo certo que o Usuário acessou, concordou e autorizou a Plataforma de Terceiros a compartilhar tais informações com a Gyra+. A Gyra+ está constantemente desenvolvendo novas integrações com Plataformas de Terceiros e a lista atualizada encontra-se na sessão do site https://credito.gyramais.com.br/#integracoes 

(iii) informações financeiras advindas de uma ou mais instituições financeiras, do Brasil ou do exterior, além do Sistema de Informações de Crédito (SCR) do Banco Central do Brasil, bureaus de crédito e quaisquer outros sistemas de pesquisa e análise, ou serviços e/ou repositórios de dados públicos ou privados aos quais a Gyra+ tenha acesso; 

(iv) informações decorrentes da utilização da Plataforma, o que inclui o seu endereço IP, seções da Plataforma que você acessou, tipo e linguagem do seu navegador, entre outras; 

(v) links de parceiros da Gyra+ (aplicações de internet), disponibilizados na Plataforma, acessados por você; 

(vi) dia e hora de seu acesso e atividades realizadas por meio da Plataforma; 

(vii) qualquer conteúdo que você tenha inserido na Plataforma; 

(viii) sua aceitação aos nossos termos e condições, que governam a utilização da Plataforma e sua relação com a Gyra+; e 

(ix) outras informações que permitam à Gyra+ aprimorar a experiência dos Usuários que utilizam a Plataforma. 

(x) Informações do Usuário compartilhadas nas páginas e perfis das redes sociais da Gyra+, através de mensagens diretas, menções e comentários em publicações orgânicas ou pagas.

Sem prejuízo dos dados e informações previstas acima, a Gyra+, observados os princípios e requisitos legais, também poderá ter acesso e compartilhar, nos limites da finalidade da prestação dos serviços, os dados e informações previstas pela resolução do BACEN sobre Open Banking.

Caso sejam utilizados pelo Usuário produtos ou serviços com geolocalização habilitada, o Usuário poderá estar enviando informações de localização à Gyra+ (por exemplo, localização geográfica do GPS do dispositivo móvel do Usuário). A Gyra+ poderá usar a localização física do dispositivo do Usuário com a finalidade de fornecer serviços e conteúdo personalizados baseados na localização. 
Quando o Usuário se comunicar com a Gyra+ por e-mail ou através da Plataforma, a Gyra+ poderá coletar informações sobre a comunicação do Usuário e qualquer informação que o Usuário opte por fornecer. Além disso, se o Usuário participar de qualquer pesquisa poderá fornecer determinados dados pessoais como parte de sua resposta, a menos que o Usuário responda anonimamente.

A Gyra+ pode anonimizar os dados pessoais para que o produto final não identifique pessoalmente o Usuário ou qualquer outro Usuário da Plataforma ou dos Serviços.
De acordo com a metodologia a tecnologia utilizadas pela Gyra+ para a prestação dos serviços através da Plataforma, as informações armazenadas pela Gyra+, inclusive dados pessoais, poderão ser fornecidas a terceiros, tais como instituições financeiras e outros agentes de mercado, na medida em que forem consideradas necessárias para sua análise de crédito, em consonância com a Política de Avaliação de Crédito, ou ainda para fins de eventuais registros em bureaus de crédito, por exemplo. Tais informações também poderão ser compartilhadas com afiliados, subcontratados ou parceiros comerciais da Gyra+ na forma de relatórios estatísticos sobre acesso, utilização da Plataforma e tendências, desde que não revelem a identidade dos Usuários. 

As informações submetidas por Usuários serão utilizadas pela Gyra+, com a finalidade de garantir que os serviços sejam prestados adequadamente, especificamente para fins de sua avaliação de crédito e manutenção de nosso banco de dados. Estas informações não serão divulgadas a terceiros, exceto parceiros da Gyra+ que colaborem com a referida avaliação de crédito e autoridades competentes, caso necessário. 

Para o adequado cumprimento dos serviços disponibilizados pela plataforma, o Usuário autoriza a Gyra+ e as instituições financeiras parceiras da Gyra+ a consultar informações sobre o Usuário no sistema de informação de crédito (SCR) mantido pelo Banco Central do Brasil, utilizando tais informações, inclusive para análise da capacidade de crédito do usuário, observando o princípio do tratamento não discriminatório. 

Ainda, o Usuário confirma e reitera que acessou a(s) Plataforma(s) de Terceiros e concordou e autorizou que a(s) Plataforma(s) de Terceiros compartilhem com a Gyra+ todas as informações do Usuário disponíveis na base de dados das Plataformas de Terceiros que se façam necessárias à adequada execução dos serviços contratados através da Plataforma, incluindo, sem limitação, histórico de vendas e cancelamentos, informações de estoque, tempo de despacho, informações de cadastro, avaliações de clientes, e qualquer outra informação relacionada à operação do usuário nas plataformas de terceiros. 

O Usuário reconhece e aceita que a Gyra+ não terá qualquer gerência e/ou participação no tratamento e transferência de dados disponibilizados pelos Usuários nas Plataformas de Terceiros, não sendo de qualquer forma responsável pelos mesmos.

Em hipótese alguma a Gyra+ comercializará as informações dos usuários. 

Identificação de Usuários 

Ao contratar algum serviço financeiro por meio da Plataforma, as informações pessoais de cadastro do Usuário serão disponibilizadas às instituições financeiras parceiras da Gyra+ com a finalidade de cumprir com os serviços contratados através da Plataforma. 

As informações eventualmente disponibilizadas pelo Usuário em ambientes públicos da Plataforma são consideradas públicas e não gozam de qualquer prerrogativa ou expectativa de privacidade sob a legislação aplicável ou sob a presente Política de Privacidade. Por isso, recomendamos aos Usuários que sejam criteriosos ao disponibilizar suas informações pessoais ou expressarem opiniões em ambientes públicos. 

Atualização de Dados 

Você poderá, a qualquer momento, alterar, editar ou solicitar a exclusão de seus dados cadastrais através do link disponibilizado para você, por e-mail, pela Gyra+, no momento de seu cadastro. Sempre que desejar alterar ou editar seus dados cadastrais, você poderá acessar este mesmo link e realizar tais alterações. Você é responsável pela veracidade, exatidão e atualização de referidos dados, conforme previsto nos nossos Termos de Uso. 

A Gyra+ não oferece qualquer garantia de que manterá o registro de todas as atividades de todos os Usuários por um período mínimo, salvo nos casos em que tal procedimento for exigido pela legislação aplicável. O link disponibilizado para acessar sua conta na Plataforma é de sua inteira responsabilidade, de modo que recomendamos que você não forneça tal link a terceiros. 

A Gyra+ não se responsabiliza pelo uso inadequado deste link de acesso e da Plataforma por terceiros que eventualmente venham a acessar a sua conta por meio do link disponibilizado pela Gyra+ a você, ou mediante violação de segurança da plataforma. 

Toda comunicação entre você e a Plataforma será feita pelo e-mail e telefone (incluindo mensagens de texto sms e whatsapp) de usuário cadastrado. Caberá ao Usuário informar à administração da Plataforma, e por meio desta, acerca de quaisquer alterações no seu endereço de e–mail e telefone celular. Até que tal comunicação seja realizada, serão válidos e eficazes os avisos e as comunicações enviadas para o e-mail e telefone original constantes no cadastro. 

Comunicações 

Após se cadastrar na Plataforma, você poderá receber e-mails para confirmar determinadas ações e solicitações. Estas notificações, relacionadas com a confirmação de transações por meio da Plataforma, não podem ser desabilitadas pelo Usuário, uma vez que são enviadas por medida de segurança pela Plataforma. Eventualmente, poderemos responder e-mails enviados por você ou enviar pesquisas e outras comunicações (diretamente ou por empresas parceiras) que julgarmos relevantes para você, mas que são de recebimento voluntário e você poderá optar por recebê-las. 

Finalidades das Informações Coletadas, Armazenamento e Duração do Tratamento

Sem prejuízo das demais finalidades previstas por esta Política de Privacidade, as informações coletadas por meio da Plataforma são armazenadas com a finalidade de: (i) analisar, elaborar e gerenciar as transações de crédito que os Usuários realizam por meio da Plataforma; (ii) classificar e agrupar informações de Usuários, assim como consolidar dados estatísticos a respeito dos mesmos, inclusive para definir a composição da base de Usuários da Plataforma e o volume de tráfego em nossos servidores; (iii) caracterizar as preferências pessoais de cada Usuário para aprimorar e customizar a sua experiência no uso da Plataforma; e (iv) contatar os Usuários diretamente para comunicar alterações das ferramentas e novas funcionalidades da Plataforma. 

O uso das informações coletadas e/ou armazenadas exige processamento de dados que poderá ser feito pela própria Gyra+ ou por parceiros contratados para esse fim, no Brasil ou no exterior, que também estarão sujeitos a esta Política de Privacidade. 

Você expressamente concorda com a transferência desses dados a outros agentes de mercado, incluindo, sem limitação, instituições financeiras, conforme necessário para a execução das transações realizadas por meio da plataforma. 

A Gyra+ emprega sistemas de segurança usualmente adotados pelo mercado para proteger suas informações contra acessos desautorizados, mas não garante que informações pessoais dos Usuários não serão acessadas por terceiros não autorizados. A Gyra+ compromete-se a comunicar, no prazo de 72 horas, aos Usuário caso identifique qualquer incidente de segurança da informação. Usamos a tecnologia 256-bit Advanced Encryption Standard (AES-256) para proteger a transmissão dos dados de identificação pessoal de nossos Usuários. Esta tecnologia encripta as informações antes da transmissão pela Internet entre o seu computador e os servidores da Plataforma, garantindo assim a segurança de todos os dados transmitidos. 

Exceto conforme previsto nesta Política de Privacidade e nos Termos de Uso, a Gyra+ não divulga a terceiros informações pessoais identificáveis fornecidas pelos Usuários. No entanto, caso seja solicitada por ordem expedida por autoridade competente no cumprimento de suas atribuições legais (tanto no Brasil como no exterior), ou em caso de violações ou suspeita de violações desta Política de Privacidade, dos Termos de Uso ou da legislação aplicável, a Gyra+ poderá ser exigida a disponibilizar tais informações. A Gyra+ se compromete a revelar as informações limitando-se ao mínimo necessário para atingir as finalidades exigidas. 

As suas informações pessoais também poderão ser disponibilizadas a terceiros no caso de fusão, aquisição ou venda de ativos da Gyra+, bem como se entrar em processo de recuperação judicial ou falência, ou ainda, passar por qualquer mudança de controle. Caso o processamento das informações após estes eventos seja alterado, a Gyra+ notificará você por meio do e-mail informado em seu cadastro. 

A Plataforma adotará todas as precauções físicas, técnicas e administrativas razoáveis para resguardar as informações (incluindo dados pessoais) fornecidas pelo Usuário, as quais serão armazenadas em ambiente seguro e protegidas com os sistemas de segurança normalmente utilizados. O acesso às informações (incluindo dados pessoais) coletadas é restrito a pessoas autorizadas que necessitem ter conhecimento de tais informações, as quais também estão obrigadas a observar esta Política de Privacidade.
As informações (incluindo Dados Pessoais) fornecidas pelo Usuário serão retidas durante todo o tempo que o Usuário mantiver uma conta ativa na Plataforma ou conforme necessário para prestar os serviços ao Usuário e por um período posterior adicional que permita: (i) ao Usuário recuperar sua conta se decidir retornar após a ter cancelado, (ii) à Gyra+ cumprir com contratos e/ou com a regulamentação ou legislação aplicável; (iii) à Gyra+ analisar suas operações e/ou os serviços prestados, sempre observada a legislação aplicável. 
Para obter mais informações sobre onde e por quanto tempo as informações (incluindo Dados Pessoais) são armazenadas, bem como sobre os direitos de exclusão e de portabilidade, entre em contato com o encarregado de proteção de dados da Plataforma no e-mail falemais@gyramais.com.

Sites de Terceiros 

A Plataforma poderá fornecer links para outros sites da internet ou outros recursos. Estes links serão disponibilizados como uma conveniência aos usuários e, como a Gyra+ não tem controle sobre tais sites ou recursos externos, o Usuário reconhece e concorda que a Gyra+ não é responsável e não endossa ou se responsabiliza por qualquer conteúdo, propaganda, produtos, serviços, assim como pelos termos de uso e políticas de privacidade desses sites ou outros materiais contidos ou disponibilizados por intermédio dos mesmos. neste sentido, sugerimos que o usuário se informe sobre os termos de uso, políticas de privacidade e demais documentos que possam reger seu acesso ou utilização de sites de terceiros antes de acessar, fornecer informações ou utilizar quaisquer recursos nesses ambientes. 

Sites, conteúdos e aplicativos de terceiros (incluindo, sem limitação, outros Usuários), assim como afiliados, subcontratados ou parceiros comerciais da Gyra+ poderão utilizar seus próprios cookies ou beacons, os quais podem não operar nos mesmos termos desta Política de Privacidade. A Gyra+ não se responsabiliza por estes sites e conteúdos e, ainda, não compartilha, subscreve, monitora, valida ou aceita a forma como esses sites ou ferramentas de armazenamento de conteúdo coletam, processam, usam e transferem suas informações pessoais. Recomendamos que você consulte as respectivas políticas de privacidade de tais sites para se informar adequadamente a respeito do uso de suas informações pessoais pelos mesmos. 

O Usuário expressamente reconhece e concorda que a Gyra+ não será responsável, direta ou indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados por, ou em conexão com, as informações, bens e serviços disponíveis em, ou através de, tais sites de terceiros. 

O que são “Cookies”, “Beacons” e “Clickstream”? 

Ao navegar na Plataforma ou utilizar qualquer um de seus recursos ou ferramentas, um cookie será enviado para o seu computador. Um cookie é um fragmento de dado que identifica você como um Usuário único, reconhecendo, acompanhando e armazenando suas informações de navegação. Os cookies são utilizados para proporcionar a você conteúdo personalizado, bem como para facilitar e agilizar o preenchimento de determinados campos. Caso configure seu navegador para recusar cookies, a Plataforma poderá não operar corretamente ou ocasionar demasiada demora para carregar os conteúdos. 

Os beacons (também conhecidos como pixel tags ou clear gifs) servem para ajudar a gerenciar e aperfeiçoar nossos serviços, sistemas, publicidade e anúncios online e demais ferramentas. Beacons possibilitam o reconhecimento de um cookie de um navegador quando este visita o site no qual o beacon está localizado e registrar qual publicidade de banner leva o Usuário a determinado site. 

O clickstream ou dados clickstream indicam as páginas específicas da internet visitadas por um Usuário, assim como a maneira ou o padrão de tráfego pelo qual o Usuário se desloca de uma página para outra. Os dados clickstream não contêm ou revelam quaisquer dados de identificação pessoal sobre o Usuário e a Gyra+ não compara estes dados com os dados pessoais que você nos fornece. 


Suporte 

Para maiores esclarecimentos ou quaisquer dúvidas sobre a Política de Privacidade, a Gyra+ está disponível para atendê-lo por meio do e-mail suporte@gyramais.com. 

Alterações da Política de Privacidade 

A presente Política de Privacidade poderá ser alterada a qualquer tempo, a exclusivo critério da Gyra+, dispensada a sua notificação prévia, sendo responsabilidade do Usuário visitar essa página de tempos em tempos para permanecer informado a respeito do tratamento conferido aos seus dados pessoais e da Política de Privacidade como um todo. 

Caso as alterações sejam significativas, poderemos colocar um aviso com mais destaque em nosso site, ou lhe enviar um e-mail com a nova versão da Política de Privacidade. A contínua utilização da Plataforma pelo Usuário significa sua aderência e concordância com a atual versão desta Política de Privacidade, mas o Usuário sempre poderá manifestar sua discordância através do e-mail  falemais@gyramais.com.

Nas hipóteses em que as alterações a esta Política de Privacidade resultarem em mudanças nas práticas de tratamento de dados pessoais que dependam do seu consentimento, solicitaremos o seu consentimento com os novos termos da Política de Privacidade em relação ao tratamento de dados e finalidades indicados.

Consentimento

Ao ler esta Política de Privacidade e clicar, ao final, em "Eu li, estou ciente das condições de tratamento dos meus dados pessoais e forneço meu consentimento, quando aplicável, conforme descrito nesta Política de Privacidade.", você consente com o tratamento dos dados pessoais nas formas aqui indicadas. 

O tratamento de seus dados pessoais é condição necessária para que a Gyra+, através da Plataforma, possa prestar os serviços. Caso você tenha dúvidas sobre qualquer um dos termos explicados aqui, estamos à disposição em nossos canais de atendimento para ajudá-lo.

Geral 

A proibição, invalidade ou inexequibilidade de qualquer disposição desta Política de Privacidade não invalidará ou afetará a mesma como um todo, devendo permanecer em vigor todas as demais disposições deste instrumento que não tenham sido afetadas pela proibição, invalidade ou inexequibilidade da disposição inoperante. 

Legislação Aplicável e Foro de Eleição

Esta Política de Privacidade é regida pelas leis da República Federativa do Brasil. As partes elegem desde já o foro da Cidade Rio de Janeiro/RJ para dirimir quaisquer dúvidas e/ou controvérsias relacionadas ao acesso e uso da Plataforma, dos Serviços ou às disposições desta Política de Privacidade, com exclusão de qualquer outro por mais privilegiado que seja.


Data da última atualização: 02 de fevereiro de 2021.`;

export {
  termsOfUse,
  termsOfUseBadesc,
  termsOfUseBadescWoman,
  termsOfUseBadescGeneral,
  termsOfUseBadescInovacao,
  privacyPolicy,
};
